import { Text, Title } from "@components/Typography";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface AuthCardProps {
  children?: ReactNode;
  isOverlay?: boolean;
}

const AuthCard: React.FC<AuthCardProps> = ({ children, isOverlay }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 bg-white py-8 px-16 rounded-lg shadow min-w-[500px]">
      <div>
        <div className="flex justify-center items-center w-full">
          <Title style={{ fontWeight: "bolder" }} level={1}>
            {t("Get Started")}
          </Title>
        </div>
        <div className="flex my-3 justify-center items-center w-full">
          <Text className="text-center">{t("Welcome to the admin 🧙‍♂️ panel")}</Text>
        </div>
      </div>
      <div>{children}</div>
      <div />
    </div>
  );
};

export default AuthCard;
