import Header from "@components/PageWrapper/Header";
import MainNavigation from "@components/PageWrapper/MainNavigation.component";
import { SidebarComponents } from "@components/PageWrapper/sidebars/Sidebar.component";
import { useRouter } from "next/router";
import React from "react";

export type Props = {
  children: React.ReactNode | React.ReactNode[] | undefined;
};

export const PublicPageWithHeader: React.FC<Props> = ({ children }) => {
  const router = useRouter();
  const activeCategory = router.route.split("/")[1] as SidebarComponents;

  return (
    <div className="flex flex-col h-full min-w-[1366px] font-inter bg-[#f8fafc] dark:bg-gray-900">
      <Header />
      <div className="h-full w-full flex overflow-hidden">
        <MainNavigation activeCategory={activeCategory} />
        <div className="flex-auto relative">{children}</div>
      </div>
    </div>
  );
};

export default PublicPageWithHeader;
