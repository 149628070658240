import Button from "@components/Button";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from "@src/features/auth/useAuth";
import { browserLogger as logger } from "@src/services/logger";

const LoginWithGoogleButton: React.FC = () => {
  const { t } = useTranslation('auth');
  const { signInWihGoogle } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleGoogleLogin = async () => {
    try {
      setLoading(true);

      await signInWihGoogle();
      // hack to show the loading state for 3 seconds (otherwise it's too fast and confusing for the user)
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const error = err as Error;
      logger.error(`handleGoogleLogin: ${error.message}`);
      setLoading(false);
    }
  };

  return (
    <Button
      loading={loading}
      onClick={handleGoogleLogin}
      type="primary"
      // icon={<img className='w-6 h-6' src='https://www.svgrepo.com/show/475656/google-color.svg' loading='lazy' alt='google logo' />}
      className='w-full'
    >
      {t('Login with Google')}
    </Button>
  );
};

export default LoginWithGoogleButton;
