import { CheckOutlined, CopyOutlined, SearchOutlined } from "@ant-design/icons";
import Button from "@components/Button";
import Tooltip from "@components/Tooltip";
import { Input as AntInput, InputNumber as AntInputNumber, InputNumberProps } from "antd";
import { PasswordProps, SearchProps, TextAreaProps } from "antd/lib/input";
import React, { useState } from "react";
import { InputProps, CopyProps } from "./Input.types";

const { TextArea: AntTextArea } = AntInput;

const Input: React.FunctionComponent<InputProps> = (props) => {
  return <AntInput {...props} />;
};

export const InputNumber = ({ style, ...props }: InputNumberProps & {
  children?: React.ReactNode;
} & {
  ref?: React.Ref<HTMLInputElement> | undefined;
}) => {
  return <AntInputNumber style={{ ...style, width: "100%" }} {...props} />;
};

export const Search = (props: SearchProps) => {
  return <AntInput {...props} prefix={<SearchOutlined />} />;
};

export const InputPassword = (props: PasswordProps) => {
  return <AntInput.Password {...props} />;
};

export const InputCopy = ({ style, tooltip, ...props }: CopyProps) => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleClick = () => {
    setCopied(true);
    navigator.clipboard.writeText(String(props.value));

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <AntInput.Group compact>
      <AntInput {...props} style={{ ...style, width: "calc(100% - 200px)", borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }} />
      <Tooltip title={tooltip}>
        <Button icon={copied ? <CheckOutlined /> : <CopyOutlined />} onClick={handleClick} style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}>
          {copied ? "Copied" : "Copy"}
        </Button>
      </Tooltip>
    </AntInput.Group>
  );
};

export const TextArea = ({ style, ...props }: TextAreaProps) => {
  return <AntTextArea {...props} />;
};

export default Input;
