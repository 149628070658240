import React from 'react';
import { Tooltip as AntTooltip } from "antd"
import { TooltipProps } from './Tooltip.types';

const Tooltip: React.FC<TooltipProps> = (props) => {

  return (
    <AntTooltip {...props} />
  )
};

export default Tooltip;