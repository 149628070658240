import React from "react";
import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "@services/redux";
import { navigationActions, selectNavigation } from "@services/redux/reducers/navigation/navigation.reducer";
import Sidebar, { SidebarComponents } from "@components/PageWrapper/sidebars/Sidebar.component";
import { isNullOrEmpty } from "@src/utils/utils";
import Button from "@components/Button";
import { MenuOutlined } from "@ant-design/icons";
import MainNavigation from "@components/PageWrapper/MainNavigation.component";
import Header from "@components/PageWrapper/Header";

export type Props = {
  children: React.ReactNode | React.ReactNode[] | undefined;
};

export const PublicPageWithHeaderAndMenu: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { open } = useAppSelector(selectNavigation);

  const handleExpand = () => {
    dispatch(navigationActions.toggleSidebar());
  };

  const activeCategory = router.route.split("/")[1] as SidebarComponents;
  const hasSidebar = !isNullOrEmpty(activeCategory);

  return (
    <div className="flex flex-col h-full min-w-[1366px] font-inter bg-[#f8fafc] dark:bg-gray-900">
      <Header />
      <div className="h-full w-full flex overflow-hidden">
        <MainNavigation activeCategory={activeCategory} />
        <div className="flex-auto relative">
          <div className={`absolute top-0 bottom-0 left-0 ${open && hasSidebar ? "w-[230px]" : "w-0"} transition-all bg-white border-r overflow-auto`}>
            <Sidebar activeCategory={activeCategory} />
          </div>
          <div className={`absolute top-0 bottom-0 ${open && hasSidebar ? "left-[230px]" : "left-0"} right-0 transition-all`}>
            <div className="h-full w-full overflow-auto">{children}</div>
          </div>
          <div className={`absolute top-[15px] ${open && hasSidebar ? "left-[217px]" : "left-[-12px]"} transition-all`}>
            <Button icon={<MenuOutlined style={{ fontSize: 10 }} />} size="small" shape="circle" onClick={handleExpand} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicPageWithHeaderAndMenu;
