import React from "react";
import { PublicEmptyPage } from "@components/PageWrapper/Public";
import LoginPage from "@src/features/auth/LoginPage";

const Page: React.FC = () => {
  return (
    <PublicEmptyPage>
      <LoginPage />
    </PublicEmptyPage>
  );
};

export default Page;
