import LegalLinks from "@components/LegalLinks";
import { Text } from "@components/Typography";
import AuthCard from "@src/features/auth/AuthPage/AuthCard";
import { PRIMARY_COLOR } from "@src/services/theme/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import { AuthPageProps } from "./AuthPage.types";

const AuthPage: React.FC<AuthPageProps> = ({ children, isOverlay = false }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col h-full w-full">
      <header className="bg-white p-3">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 6C0 2.68629 2.68629 0 6 0H30V24C30 27.3137 27.3137 30 24 30H0V6Z" fill={PRIMARY_COLOR} />
          <path
            d="M8.29688 9.36364H12.2287L14.9332 15.9545H15.0696L17.7741 9.36364H21.706V21H18.6151V14.2727H18.5241L15.9332 20.9091H14.0696L11.4787 14.2273H11.3878V21H8.29688V9.36364Z"
            fill="white"
          />
        </svg>
      </header>
      <div className="flex-grow flex w-full bg-slate-100 flex-col justify-center items-center">
        <div className="flex flex-col py-8">
          <AuthCard isOverlay={isOverlay}>{children}</AuthCard>
          <div className="w-max-[500px] px-8 flex items-center justify-center">
            <LegalLinks />
          </div>
        </div>
      </div>
      <footer className="bg-white flex justify-center items-center w-full items-center  p-4">
        <Text>{t("2023 © Metacommerce. All Rights Reserved.")}</Text>
      </footer>
    </div>
  );
};

export default AuthPage;
