import React from "react";

export type Props = {
  children: React.ReactNode | React.ReactNode[] | undefined;
  backgroundClass?: string;
};
export const PublicEmptyPage: React.FC<Props> = ({ children, backgroundClass = "dark:bg-gray-900" }) => {
  return <div className={`flex flex-col h-full min-w-[1366px] font-inter bg-[#f8fafc] ${backgroundClass}`}>{children}</div>;
};

export default PublicEmptyPage;
