import React from "react";
import { useTranslation } from "react-i18next";

const LegalLinks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex justify-between pt-6">
      <a href="https://www.metacommerce.app/terms" target="_blank" rel="noreferrer">
        {t("Terms of Use")}
      </a>
      <a href="https://www.metacommerce.app/privacy" target="_blank" rel="noreferrer">
        {t("Privacy Policy")}
      </a>
      <a href="https://discord.gg/By5yjukX" target="_blank" rel="noreferrer">
        {t("Support")}
      </a>
    </div>
  );
};

export default LegalLinks;
