import { LockOutlined } from "@ant-design/icons";
import Button from "@components/Button";
import Input, { InputPassword } from "@components/Input";
import AuthPage from "@src/features/auth/AuthPage";
import { useAuth } from "@src/features/auth/useAuth";
import { useNavigation } from "@src/features/navigation/hooks";
import FeaturesRoutes from "@src/routes/routes";
import { browserLogger as logger } from "@src/services/logger";
import { Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginWithGoogleButton from './LoginWithGoogleButton';

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const { signIn } = useAuth();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { navigateTo } = useNavigation();

  const handleSignIn = async () => {
    form
      .validateFields()
      .then(async (data) => {
        setLoading(true);

        const { email, password } = data;

        await signIn({ email, password });
        await navigateTo(FeaturesRoutes.home);
      })
      .catch((error) => {
        logger.error(error);
        form.setFields([
          {
            name: "email",
            errors: [error.message || t("Invalid email or password")],
          },
          {
            name: "password",
            errors: [error.message || t("Invalid email or password")],
          },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePressEnter = async (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key !== "Enter") {
      return;
    }

    return handleSignIn();
  };

  return (
    <AuthPage>
      <div className="w-full flex flex-col justify-center items-cetner">
        <LoginWithGoogleButton />
        <div className='py-2 flex items-center gap-2 w-full'>
          <div className='flex-1 border-b border-gray-200' />
          <span className='text-gray-500'>OR</span>
          <div className='flex-1 border-b border-gray-200' />
        </div>
        <Form form={form} layout="vertical" requiredMark="optional" onKeyDown={handlePressEnter}>
          <Form.Item name="email" rules={[{ required: true }]}>
            <Input autoComplete="username" type="email" placeholder={t("Email")} />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }]}>
            <InputPassword autoComplete="current-password" placeholder={t("Password")} aria-required type="password" />
          </Form.Item>
        </Form>
        <Button loading={loading} onClick={handleSignIn} className="w-full" type="primary" icon={<LockOutlined />}>
          {t("Sign in")}
        </Button>
      </div>
    </AuthPage>
  );
};

export default LoginPage;
